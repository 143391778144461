var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"mx-1 mx-xl-4",attrs:{"align-h":"center"}},[_c('b-col',{staticClass:"bg-light p-4",staticStyle:{"border-radius":"10px"},attrs:{"cols":"12"}},[_c('div',{staticClass:"table-div mb-3",style:(`border: ${_vm.style.borderStyle};`)},[_c('div',{staticClass:"table-header"},[_c('div',{class:_vm.useClasses('HEADER'),style:(_vm.useStyles('HEADER', {
              'min-width': '100px',
              'max-width': '100px'
            }))},[_c('span',[_vm._v(_vm._s(_vm.tableHeaders[0].label))])]),_c('div',{class:_vm.useClasses('HEADER'),style:(_vm.useStyles('HEADER', {
              'min-width': '150px',
              'max-width': '150px'
            }))},[_c('span',[_vm._v(_vm._s(_vm.tableHeaders[1].label))])]),_c('div',{class:_vm.useClasses('HEADER'),style:(_vm.useStyles('HEADER', {
              'min-width': '250px',
              width: '100%'
            }))},[_c('span',[_vm._v(_vm._s(_vm.tableHeaders[2].label))])]),_c('div',{class:_vm.useClasses('HEADER'),style:(_vm.useStyles('HEADER', {
              'min-width': '250px',
              width: '100%'
            }))},[_c('span',[_vm._v(_vm._s(_vm.tableHeaders[3].label))])]),_c('div',{class:_vm.useClasses('HEADER'),style:(_vm.useStyles('HEADER', {
              'min-width': '180px',
              'max-width': '180px'
            }))},[_c('span',[_vm._v(" "+_vm._s(_vm.tableHeaders[4].label)+" ")])]),_c('div',{class:_vm.useClasses('HEADER'),style:(_vm.useStyles('HEADER', {
              'min-width': '180px',
              'max-width': '180px'
            }))},[_c('span',[_vm._v(" "+_vm._s(_vm.tableHeaders[5].label)+" ")])])]),(!_vm.isLoading)?_c('div',{staticClass:"table-body-row",attrs:{"id":"table-body-row"}},_vm._l((_vm.tableContents),function(item,index){return _c('div',{key:index,staticClass:"table-body-column",style:({
            'background-color':
              _vm.rowSelected && _vm.rowSelected.id === item.id
                ? `${_vm.style.rowSelectedBackgroundColorStyle}`
                : ''
          }),on:{"click":function($event){;['VIEW'].includes(_vm.mode) ? _vm.rowSelectedHandler([item]) : () => {}}}},[_c('div',{class:_vm.useClasses('BODY_START'),style:(_vm.useStyles('BODY', {
                'min-width': '100px',
                'max-width': '100px'
              }))},[_c('b-form-select',{attrs:{"options":_vm.orderOptions,"disabled":['VIEW'].includes(_vm.mode)},on:{"change":function($event){return _vm.orderChangedHandler($event, item)}},model:{value:(item.order),callback:function ($$v) {_vm.$set(item, "order", $$v)},expression:"item.order"}})],1),_c('div',{class:_vm.useClasses('BODY_CENTER'),style:(_vm.useStyles('BODY', {
                'min-width': '150px',
                'max-width': '150px'
              }))},[(['VIEW'].includes(_vm.mode))?_c('div',[_c('span',[_vm._v(_vm._s(item.accountNumber))])]):_c('div',{staticClass:"d-flex align-items-center"},[_c('b-input',{staticClass:"w-100",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.accountInputEnterKeyHandler(item)}},model:{value:(item.accountNumber),callback:function ($$v) {_vm.$set(item, "accountNumber", $$v)},expression:"item.accountNumber"}}),_c('b-icon',{staticClass:"ml-1",attrs:{"icon":"table","font-scale":"0.9"},on:{"click":function($event){return _vm.selectAccountButtonClicked(item)}}})],1)]),_c('div',{class:_vm.useClasses('BODY_START'),style:(_vm.useStyles('BODY', {
                'min-width': '250px',
                width: '100%'
              })),on:{"click":function($event){;['CREATE', 'UPDATE'].includes(_vm.mode)
                ? _vm.rowSelectedHandler([item])
                : () => {}}}},[_c('span',[_vm._v(_vm._s(item.accountName))])]),_c('div',{class:_vm.useClasses('BODY_START'),style:(_vm.useStyles('BODY', {
                'min-width': '250px',
                width: '100%'
              }))},[(['VIEW'].includes(_vm.mode))?_c('span',[_vm._v(_vm._s(item.remark))]):_c('b-input',{attrs:{"placeholder":"หมายเหตุ"},on:{"keydown":function($event){return _vm.onKeyDownHandlerRemarkInput($event, item)}},model:{value:(item.remark),callback:function ($$v) {_vm.$set(item, "remark", $$v)},expression:"item.remark"}})],1),_c('div',{class:_vm.useClasses('BODY_END'),style:(_vm.useStyles('BODY', {
                'min-width': '180px',
                'max-width': '180px'
              }))},[(['VIEW'].includes(_vm.mode))?_c('span',[_vm._v(" "+_vm._s(item.debitAmount !== 0 ? _vm.mxNumeralThousandSeperate(item.debitAmount, 2) : '')+" ")]):_c('number-input',{attrs:{"itemProps":item,"idProps":'ITEM_1-' + index,"valueProps":item.debitAmount !== 0 ? item.debitAmount : ''},on:{"numberUpdated":function($event){return _vm.numberUpdatedHandler($event, item, 'DEBIT')},"rebalanceRequested":_vm.rebalanceRequestedHandler}})],1),_c('div',{class:_vm.useClasses('BODY_END'),style:(_vm.useStyles('BODY', {
                'min-width': '180px',
                'max-width': '180px'
              }))},[(['VIEW'].includes(_vm.mode))?_c('span',[_vm._v(" "+_vm._s(item.creditAmount !== 0 ? _vm.mxNumeralThousandSeperate(item.creditAmount, 2) : '')+" ")]):_c('number-input',{attrs:{"itemProps":item,"idProps":'ITEM_2-' + index,"valueProps":item.creditAmount !== 0 ? item.creditAmount : ''},on:{"numberUpdated":function($event){return _vm.numberUpdatedHandler($event, item, 'CREDIT')},"rebalanceRequested":_vm.rebalanceRequestedHandler}})],1)])}),0):_c('div',{staticClass:"table-body-max-height"},[_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2",attrs:{"variant":"primary"}}),_c('strong',[_vm._v("กำลังโหลดข้อมูล ...")])],1)]),_c('div',{staticClass:"table-footer"},[_c('div',{class:_vm.useClasses('FOOTER_CENTER'),style:(_vm.useStyles('FOOTER', {
              'min-width': '100px',
              'max-width': '100px'
            }))},[_c('span',[_vm._v(_vm._s(_vm.rowSelectedStatus()))])]),_c('div',{class:_vm.useClasses('FOOTER_CENTER'),style:(_vm.useStyles('FOOTER', {
              'min-width': '150px',
              'max-width': '150px'
            }))},[_c('span',[_vm._v(_vm._s(''))])]),_c('div',{class:_vm.useClasses('FOOTER_CENTER'),style:(_vm.useStyles('FOOTER', {
              'min-width': '250px',
              width: '100%'
            }))},[_c('span',[_vm._v(_vm._s(''))])]),_c('div',{class:_vm.useClasses('FOOTER_CENTER'),style:(_vm.useStyles('FOOTER', {
              'min-width': '250px',
              width: '100%'
            }))},[_c('span',[_vm._v(_vm._s('ยอดรวม'))])]),_c('div',{class:_vm.useClasses('FOOTER_RIGHT'),style:(_vm.useStyles('FOOTER', {
              'min-width': '180px',
              'max-width': '180px'
            }))},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.mxNumeralThousandSeperate(_vm.totalDebit, 2)))])])]),_c('div',{class:_vm.useClasses('FOOTER_RIGHT'),style:(_vm.useStyles('FOOTER', {
              'min-width': '180px',
              'max-width': '180px'
            }))},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.mxNumeralThousandSeperate(_vm.totalCredit, 2)))])])])]),(['CREATE', 'UPDATE'].includes(_vm.mode))?_c('div',{staticClass:"d-flex"},[_c('div',{class:_vm.useClasses('BODY_START'),style:(_vm.useStyles('BODY', {
              'min-width': '120px',
              'max-width': '120px'
            }))},[_c('b-button',{staticClass:"w-100",attrs:{"variant":"success"},on:{"click":function($event){return _vm.addNewDetailItemButtonClicked()}}},[_vm._v(" เพิ่มรายการ ")])],1),_c('div',{class:_vm.useClasses('BODY_START'),style:(_vm.useStyles('BODY', {
              'min-width': '120px',
              'max-width': '120px'
            }))},[_c('b-button',{staticClass:"w-100",attrs:{"variant":_vm.tableContents.length <= 0 || !_vm.rowSelected
                ? 'outline-danger'
                : 'danger',"disabled":_vm.tableContents.length <= 0 || !_vm.rowSelected},on:{"click":function($event){return _vm.removeDetailItemButtonClicked()}}},[_vm._v(" ลบรายการ ")])],1),_c('div',{class:_vm.useClasses('BODY_START'),style:(_vm.useStyles('BODY', {
              'min-width': '120px',
              'max-width': '120px'
            }))},[_c('b-button',{staticClass:"w-100",attrs:{"variant":_vm.tableContents.length <= 0 ? 'outline-info' : 'info',"disabled":_vm.tableContents.length <= 0},on:{"click":function($event){return _vm.resetDetailItemButtonClicked()}}},[_vm._v(" ลบทั้งหมด ")])],1)]):_vm._e(),_c('select-account-modal',{attrs:{"displayModalProp":_vm.selectAccountModalShow,"accountProp":_vm.focusRow},on:{"modalClosed":function($event){_vm.selectAccountModalShow = false},"accountSelected":_vm.accountSelectedHandler}})],1)]),_c('error-modal',{attrs:{"displayProp":!!_vm.errMsg,"errorMessageProp":_vm.errMsg},on:{"modalClosed":function($event){_vm.errMsg = null}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }