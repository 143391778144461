<script>
/*
Props: {
  mode: 'CREATE' | 'UPDATE' | 'VIEW'
  informationProp: {
    totalCredit: number
    totalDebit: number
  }
  detailsProp: {
    id: number
    order: number
    accountNumber: string
    accountName: string
    remark: string | null
    debitAmount: string | null
    creditAmount: string | null
  }[]
}
Events: {
  orderUpdated: {
    id: number
    order: number
  }[]
  detailUpdated: {
    id: number
    accountNumber: string | null
    accountName: string | null
    debitAmount: string | null
    creditAmount: string | null
    remark: string | null
    order: number
  }
}
*/

import _ from 'lodash'
import { Decimal } from 'decimal.js'

import NumberInput from '@/components/input/NumberInput.vue'
import SelectAccountModal from '@/components/modal/SelectAccountModal.vue'
import { numberMixins, toastMixins, inputFieldMixins } from '@/mixins'
import ge1mastService from '@/services/master/ge1mast.js'

import styleMixins from './mixins/style.js'

export default {
  mixins: [numberMixins, toastMixins, inputFieldMixins, styleMixins],
  components: {
    SelectAccountModal,
    NumberInput
  },
  props: {
    mode: String,
    informationProp: Object,
    detailsProp: Array
  },
  data() {
    return {
      tableHeaders: [
        { key: 'order', label: 'ลำดับที่' },
        { key: 'acc', label: 'เลขที่บัญชี' },
        { key: 'name', label: 'ชื่อบัญชี' },
        { key: 'description', label: 'คำอธิบาย' },
        { key: 'debit', label: 'เดบิท' },
        { key: 'credit', label: 'เครดิต' }
      ],
      isLoading: false,
      tableContents: [],
      rowSelected: null,
      totalDebit: 0,
      totalCredit: 0,
      orderOptions: [],
      focusRow: null,
      selectAccountModalShow: false,
      forbiddenLedgerAccountLinkers: [],
      errMsg: null
    }
  },
  computed: {
    rows() {
      return this.tableContents.length
    }
  },
  watch: {
    informationProp() {
      this.reAssignInformationProp()
    },
    detailsProp() {
      this.reAssignDetailsProp()
    },
    selectAccountModalShow() {
      if (!this.selectAccountModalShow) {
        this.focusRow = null
      }
    },
    tableContents: {
      handler(val) {
        this.recalculateDebitCreditAmount()
        if (['CREATE', 'UPDATE'].includes(this.mode)) {
          this.$emit('detailUpdated', this.tableContents)
        }
      },
      deep: true
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    async initialize() {
      await this.fetchForbiddenLedgerAccountLinkers()
      this.reAssignInformationProp('informationProp')
      this.reAssignDetailsProp('detailsProp')
    },
    async fetchForbiddenLedgerAccountLinkers() {
      try {
        const res = await ge1mastService.getForbiddenLedgerAccountLinkers()
        this.forbiddenLedgerAccountLinkers = res.data.data
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลบัญชีแยกประเภทไม่สำเร็จ'
        this.errMsg = errMessage
      }
    },
    reAssignInformationProp() {
      if (!_.isNil(this.informationProp)) {
        const { totalCredit, totalDebit } = this.informationProp
        this.totalCredit = totalCredit
        this.totalDebit = totalDebit
      }
    },
    reAssignDetailsProp() {
      if (!_.isNil(this.detailsProp)) {
        this.tableContents = this.detailsProp
        this.orderOptions = this.tableContents.map((el, index) => {
          return { text: index + 1, value: index + 1 }
        })
      }
    },
    orderChangedHandler(newOrder, itemSelected) {
      let cloneTableContents = this.tableContents.map((el) => ({ ...el }))
      const filteredTableContents = cloneTableContents.filter(
        (el) => el.id !== itemSelected.id
      )
      const head = filteredTableContents.slice(0, newOrder - 1)
      const tail = filteredTableContents.slice(newOrder - 1)
      const finalResult = [...head, { ...itemSelected }, ...tail].map(
        (el, index) => ({
          ...el,
          id: index + 1,
          order: index + 1
        })
      )
      this.tableContents = finalResult.map((el) => ({ ...el }))
    },
    rowSelectedStatus() {
      let position = 0
      if (this.rowSelected) {
        this.tableContents.forEach((el, index) => {
          if (el.id === this.rowSelected.id) position = index + 1
        })
      }
      return position ? `${position} / ${this.tableContents.length}` : ''
    },
    addNewDetailItemButtonClicked() {
      this.tableContents.push({
        id: this.tableContents.length + 1,
        order: this.tableContents.length + 1,
        accountNumber: null,
        accountName: null,
        remark: null,
        debitAmount: null,
        creditAmount: null
      })

      // Auto scroll to bottom
      window.setTimeout(function () {
        var elem = document.getElementById('table-body-row')
        elem.scrollTop = elem.scrollHeight
      }, 500)
    },
    removeDetailItemButtonClicked() {
      if (this.tableContents.length > 0 && this.rowSelected) {
        let count = 1
        this.tableContents = this.tableContents.reduce((acc, item) => {
          if (item.id !== this.rowSelected.id) {
            acc.push({
              ...item,
              id: count,
              order: count
            })
            count++
          }
          return acc
        }, [])
        this.rowSelected = null
      }
    },
    resetDetailItemButtonClicked() {
      if (this.tableContents.length > 0) {
        this.tableContents = []
        this.rowSelected = null
      }
    },
    selectAccountButtonClicked(item) {
      this.focusRow = item
      this.selectAccountModalShow = true
    },
    async accountInputEnterKeyHandler(item) {
      try {
        if (!item.accountNumber) {
          this.errMsg = `กรุณาเลือกบัญชี`
          return
        }

        const res =
          await ge1mastService.getGeneralLedgerMasterAccountByAccountNumber(
            item.accountNumber
          )
        const account = res.data.data

        let findErrors = this.validateSelectedAccount(account)
        if (findErrors.length > 0) {
          const errorMessage = findErrors.join(', ')
          this.errMsg = `ไม่สามารถเลือกบัญชี ${account.glmaccn_accountNumber} ได้เนื่องจาก ${errorMessage}`

          item.accountNumber = null
          item.accountName = null
        } else {
          item.accountNumber = account.glmaccn_accountNumber
          item.accountName = account.glmaccn_accountName

          this.mxFocusNextInputField()
        }
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ค้นหาข้อมูลของบัญชีไม่สำเร็จ'
        this.errMsg = errMessage

        item.accountNumber = null
        item.accountName = null
      }
    },
    validateSelectedAccount(selectedAccount) {
      const errors = []
      const { glmaccn_accountType, glmaccn_accountNumber } = selectedAccount

      if (glmaccn_accountType === '1') {
        errors.push('เป็นบัญชีสรุปยอด')
      }

      const forbiddenAccountNumbers = this.forbiddenLedgerAccountLinkers.map(
        (el) => el.glmaccn_accountNumber
      )
      if (forbiddenAccountNumbers.includes(glmaccn_accountNumber)) {
        errors.push('อยู่ในตัวเชื่อมโยงแยกประเภท')
      }

      return errors
    },
    accountSelectedHandler(account) {
      this.focusRow.accountName = account.accountName
      this.focusRow.accountNumber = account.accountNumber
      this.focusRow = null
    },
    recalculateDebitCreditAmount() {
      let totalDebitAmount = 0
      let totalCreditAmount = 0
      this.tableContents.forEach((content) => {
        if (!_.isNull(content.debitAmount)) {
          totalDebitAmount += +content.debitAmount
        }
        if (!_.isNull(content.creditAmount)) {
          totalCreditAmount += +content.creditAmount
        }
      })
      this.totalDebit = totalDebitAmount
      this.totalCredit = totalCreditAmount
    },
    onKeyDownHandlerRemarkInput(event, item) {
      switch (event.key) {
        case 'F4':
          this.rowSelected = item
          this.rebalanceToRowSelected()
          break
        case 'Enter':
          this.mxFocusNextInputField()
          break
      }
    },
    rebalanceToRowSelected() {
      if (this.rowSelected) {
        let totalDebitAmount = 0
        let totalCreditAmount = 0

        for (let i = 0; i < this.tableContents.length; i++) {
          const currentContent = this.tableContents[i]
          if (currentContent.order === this.rowSelected.order) {
            continue
          }
          if (currentContent.debitAmount !== null) {
            totalDebitAmount = Decimal(+totalDebitAmount)
              .plus(Decimal(+currentContent.debitAmount))
              .toNumber()
          }
          if (currentContent.creditAmount !== null) {
            totalCreditAmount = Decimal(+totalCreditAmount)
              .plus(Decimal(+currentContent.creditAmount))
              .toNumber()
          }
        }

        if (totalDebitAmount > totalCreditAmount) {
          this.rowSelected.creditAmount = `${Decimal(totalDebitAmount)
            .minus(Decimal(totalCreditAmount))
            .toNumber()}`
        } else if (totalDebitAmount < totalCreditAmount) {
          this.rowSelected.debitAmount = `${Decimal(totalCreditAmount)
            .minus(Decimal(totalDebitAmount))
            .toNumber()}`
        }

        this.rowSelected = null
      }
    },
    rebalanceRequestedHandler(itemOrder) {
      this.rowSelected = this.tableContents.find((el) => el.order === itemOrder)
      this.rebalanceToRowSelected()
    },
    numberUpdatedHandler(value, item, type) {
      switch (type) {
        case 'DEBIT':
          item.debitAmount = value === 0 || value === null ? null : `${value}`
          break
        case 'CREDIT':
          item.creditAmount = value === 0 || value === null ? null : `${value}`
          break
      }
    },
    rowSelectedHandler(e) {
      if (e.length > 0) {
        if (this.rowSelected && this.rowSelected.id === e[0].id) {
          this.rowSelected = null
        } else {
          this.rowSelected = e[0]
        }
      } else this.rowSelected = null
    },
    rowSelectedStatus() {
      let position = 0
      if (this.rowSelected) {
        this.tableContents.forEach((el, index) => {
          if (el.id === this.rowSelected.id) position = index + 1
        })
      }
      return position ? `${position} / ${this.tableContents.length}` : ''
    }
  }
}
</script>

<template>
  <b-row align-h="center" class="mx-1 mx-xl-4">
    <b-col cols="12" class="bg-light p-4" style="border-radius: 10px">
      <!-- table part -->
      <div class="table-div mb-3" :style="`border: ${style.borderStyle};`">
        <!-- Table Header -->
        <div class="table-header">
          <div
            :class="useClasses('HEADER')"
            :style="
              useStyles('HEADER', {
                'min-width': '100px',
                'max-width': '100px'
              })
            "
          >
            <span>{{ tableHeaders[0].label }}</span>
          </div>
          <div
            :class="useClasses('HEADER')"
            :style="
              useStyles('HEADER', {
                'min-width': '150px',
                'max-width': '150px'
              })
            "
          >
            <span>{{ tableHeaders[1].label }}</span>
          </div>
          <div
            :class="useClasses('HEADER')"
            :style="
              useStyles('HEADER', {
                'min-width': '250px',
                width: '100%'
              })
            "
          >
            <span>{{ tableHeaders[2].label }}</span>
          </div>
          <div
            :class="useClasses('HEADER')"
            :style="
              useStyles('HEADER', {
                'min-width': '250px',
                width: '100%'
              })
            "
          >
            <span>{{ tableHeaders[3].label }}</span>
          </div>
          <div
            :class="useClasses('HEADER')"
            :style="
              useStyles('HEADER', {
                'min-width': '180px',
                'max-width': '180px'
              })
            "
          >
            <span>
              {{ tableHeaders[4].label }}
            </span>
          </div>
          <div
            :class="useClasses('HEADER')"
            :style="
              useStyles('HEADER', {
                'min-width': '180px',
                'max-width': '180px'
              })
            "
          >
            <span>
              {{ tableHeaders[5].label }}
            </span>
          </div>
        </div>

        <!-- Table Body -->
        <div v-if="!isLoading" id="table-body-row" class="table-body-row">
          <div
            v-for="(item, index) in tableContents"
            :key="index"
            class="table-body-column"
            @click="
              ;['VIEW'].includes(mode) ? rowSelectedHandler([item]) : () => {}
            "
            :style="{
              'background-color':
                rowSelected && rowSelected.id === item.id
                  ? `${style.rowSelectedBackgroundColorStyle}`
                  : ''
            }"
          >
            <!-- order -->
            <div
              :class="useClasses('BODY_START')"
              :style="
                useStyles('BODY', {
                  'min-width': '100px',
                  'max-width': '100px'
                })
              "
            >
              <b-form-select
                v-model="item.order"
                :options="orderOptions"
                @change="orderChangedHandler($event, item)"
                :disabled="['VIEW'].includes(mode)"
              ></b-form-select>
            </div>
            <!-- account number -->
            <div
              :class="useClasses('BODY_CENTER')"
              :style="
                useStyles('BODY', {
                  'min-width': '150px',
                  'max-width': '150px'
                })
              "
            >
              <div v-if="['VIEW'].includes(mode)">
                <span>{{ item.accountNumber }}</span>
              </div>
              <div v-else class="d-flex align-items-center">
                <b-input
                  class="w-100"
                  v-model="item.accountNumber"
                  @keydown.enter="accountInputEnterKeyHandler(item)"
                ></b-input>
                <b-icon
                  class="ml-1"
                  icon="table"
                  font-scale="0.9"
                  @click="selectAccountButtonClicked(item)"
                ></b-icon>
              </div>
            </div>
            <!-- account name -->
            <div
              :class="useClasses('BODY_START')"
              :style="
                useStyles('BODY', {
                  'min-width': '250px',
                  width: '100%'
                })
              "
              @click="
                ;['CREATE', 'UPDATE'].includes(mode)
                  ? rowSelectedHandler([item])
                  : () => {}
              "
            >
              <span>{{ item.accountName }}</span>
            </div>
            <!-- remark -->
            <div
              :class="useClasses('BODY_START')"
              :style="
                useStyles('BODY', {
                  'min-width': '250px',
                  width: '100%'
                })
              "
            >
              <span v-if="['VIEW'].includes(mode)">{{ item.remark }}</span>
              <b-input
                v-else
                v-model="item.remark"
                placeholder="หมายเหตุ"
                @keydown="onKeyDownHandlerRemarkInput($event, item)"
              ></b-input>
            </div>
            <!-- debit amount -->
            <div
              :class="useClasses('BODY_END')"
              :style="
                useStyles('BODY', {
                  'min-width': '180px',
                  'max-width': '180px'
                })
              "
            >
              <span v-if="['VIEW'].includes(mode)">
                {{
                  item.debitAmount !== 0
                    ? mxNumeralThousandSeperate(item.debitAmount, 2)
                    : ''
                }}
              </span>
              <number-input
                v-else
                :itemProps="item"
                :idProps="'ITEM_1-' + index"
                :valueProps="item.debitAmount !== 0 ? item.debitAmount : ''"
                @numberUpdated="numberUpdatedHandler($event, item, 'DEBIT')"
                @rebalanceRequested="rebalanceRequestedHandler"
              ></number-input>
            </div>
            <!-- credit amount -->
            <div
              :class="useClasses('BODY_END')"
              :style="
                useStyles('BODY', {
                  'min-width': '180px',
                  'max-width': '180px'
                })
              "
            >
              <span v-if="['VIEW'].includes(mode)">
                {{
                  item.creditAmount !== 0
                    ? mxNumeralThousandSeperate(item.creditAmount, 2)
                    : ''
                }}
              </span>
              <number-input
                v-else
                :itemProps="item"
                :idProps="'ITEM_2-' + index"
                :valueProps="item.creditAmount !== 0 ? item.creditAmount : ''"
                @numberUpdated="numberUpdatedHandler($event, item, 'CREDIT')"
                @rebalanceRequested="rebalanceRequestedHandler"
              ></number-input>
            </div>
          </div>
        </div>
        <div v-else class="table-body-max-height">
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-2" variant="primary"></b-spinner>
            <strong>กำลังโหลดข้อมูล ...</strong>
          </div>
        </div>

        <!-- Table Footer -->
        <div class="table-footer">
          <div
            :class="useClasses('FOOTER_CENTER')"
            :style="
              useStyles('FOOTER', {
                'min-width': '100px',
                'max-width': '100px'
              })
            "
          >
            <span>{{ rowSelectedStatus() }}</span>
          </div>
          <div
            :class="useClasses('FOOTER_CENTER')"
            :style="
              useStyles('FOOTER', {
                'min-width': '150px',
                'max-width': '150px'
              })
            "
          >
            <span>{{ '' }}</span>
          </div>
          <div
            :class="useClasses('FOOTER_CENTER')"
            :style="
              useStyles('FOOTER', {
                'min-width': '250px',
                width: '100%'
              })
            "
          >
            <span>{{ '' }}</span>
          </div>
          <div
            :class="useClasses('FOOTER_CENTER')"
            :style="
              useStyles('FOOTER', {
                'min-width': '250px',
                width: '100%'
              })
            "
          >
            <span>{{ 'ยอดรวม' }}</span>
          </div>
          <div
            :class="useClasses('FOOTER_RIGHT')"
            :style="
              useStyles('FOOTER', {
                'min-width': '180px',
                'max-width': '180px'
              })
            "
          >
            <span>
              <span>{{ mxNumeralThousandSeperate(totalDebit, 2) }}</span>
            </span>
          </div>
          <div
            :class="useClasses('FOOTER_RIGHT')"
            :style="
              useStyles('FOOTER', {
                'min-width': '180px',
                'max-width': '180px'
              })
            "
          >
            <span>
              <span>{{ mxNumeralThousandSeperate(totalCredit, 2) }}</span>
            </span>
          </div>
        </div>

        <!-- buttons -->
        <div v-if="['CREATE', 'UPDATE'].includes(mode)" class="d-flex">
          <!-- Add button -->
          <div
            :class="useClasses('BODY_START')"
            :style="
              useStyles('BODY', {
                'min-width': '120px',
                'max-width': '120px'
              })
            "
          >
            <b-button
              class="w-100"
              variant="success"
              @click="addNewDetailItemButtonClicked()"
            >
              เพิ่มรายการ
            </b-button>
          </div>
          <!-- Remove button -->
          <div
            :class="useClasses('BODY_START')"
            :style="
              useStyles('BODY', {
                'min-width': '120px',
                'max-width': '120px'
              })
            "
          >
            <b-button
              class="w-100"
              :variant="
                tableContents.length <= 0 || !rowSelected
                  ? 'outline-danger'
                  : 'danger'
              "
              @click="removeDetailItemButtonClicked()"
              :disabled="tableContents.length <= 0 || !rowSelected"
            >
              ลบรายการ
            </b-button>
          </div>
          <!-- reset button -->
          <div
            :class="useClasses('BODY_START')"
            :style="
              useStyles('BODY', {
                'min-width': '120px',
                'max-width': '120px'
              })
            "
          >
            <b-button
              class="w-100"
              :variant="tableContents.length <= 0 ? 'outline-info' : 'info'"
              @click="resetDetailItemButtonClicked()"
              :disabled="tableContents.length <= 0"
            >
              ลบทั้งหมด
            </b-button>
          </div>
        </div>

        <!-- Select Account Modal -->
        <select-account-modal
          :displayModalProp="selectAccountModalShow"
          :accountProp="focusRow"
          @modalClosed="selectAccountModalShow = false"
          @accountSelected="accountSelectedHandler"
        ></select-account-modal>
      </div>
    </b-col>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-row>
</template>

<style scoped>
/* Input */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Table */
.table-div {
  overflow-x: auto;
}
.table-header {
  display: flex;
}
.table-body-row {
  display: flex;
  flex-direction: column;
  max-height: calc(65vh - 410px);
  min-height: 35px;
  width: fit-content;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
.table-body-row::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
@media (min-width: 1200px) {
  .table-body-row {
    width: 100%;
    max-height: calc(85vh - 410px);
    min-height: 35px;
  }
}
.table-body-row:hover {
  cursor: pointer;
}
.table-body-column {
  display: flex;
}
.table-footer {
  display: flex;
}
</style>
