import client from './../api.js'

const endpoint = '/entry/ge2gjnl'

export default {
  getAllGe2gjnl: async () => {
    return await client.get(endpoint)
  },
  getGe2gjnlById: async id => {
    return await client.get(`${endpoint}/${id}`)
  },
  getGe2gjnlDataByJournalCodeAndDocumentId: async (journalCode, documentId) => {
    return await client.get(`${endpoint}/specific/${journalCode}/${documentId}`)
  },
  getDocumentsById: async (id, querystring) => {
    return await client.get(`${endpoint}/documents/${id}?${querystring}`)
  },
  updateSequenceGe2gjnlByJournalCodeAndJournalNumber: async (
    journalCode,
    journalNumber,
    body
  ) => {
    return await client.patch(
      `${endpoint}/specific/${journalCode}/${journalNumber}/update-sequence`,
      body
    )
  },
  createGe2gjnl: async body => {
    return await client.post(`${endpoint}`, body)
  },
  updateGe2gjnlById: async (id, body) => {
    return await client.patch(`${endpoint}/${id}`, body)
  },
  getGe2gjnlDeletePermissionById: async id => {
    return await client.get(`${endpoint}/delete-permission/${id}`)
  },
  deleteGe2gjnlById: async id => {
    return await client.put(`${endpoint}/delete/${id}`)
  },
  createDocument: async body => {
    return await client.post(`${endpoint}/documents/create`, body)
  },
  updateDocument: async body => {
    return await client.patch(`${endpoint}/documents/update`, body)
  },
  deleteDocument: async (journalCode, journalNumber) => {
    return await client.put(
      `${endpoint}/documents/delete/${journalCode}/${journalNumber}`
    )
  },
  getSystemJournalBookCodes: async () => {
    return await client.get(`${endpoint}/system-journal-book-codes`)
  },
  getJournalDocumentTemplates: ({ qs }) => {
    return client.get(`${endpoint}/journal-document-templates?${qs}`)
  },
  getJournalDocumentTemplateById: templateId => {
    return client.get(`${endpoint}/journal-document-templates/${templateId}`)
  },
  createNewJournalDocumentTemplate: body => {
    return client.post(`${endpoint}/journal-document-templates`, body)
  },
  deleteJournalDocumentTemplateById: templateId => {
    return client.delete(`${endpoint}/journal-document-templates/${templateId}`)
  },
  updateJournalDocumentTemplateById: (templateId, body) => {
    return client.patch(
      `${endpoint}/journal-document-templates/${templateId}`,
      body
    )
  },
  getAvailableJournalDocumentTemplateByJournalCode: (journalCode, qs) => {
    return client.get(
      `${endpoint}/journal-document-templates/journal-code/${journalCode}?${qs}`
    )
  },
  getUserIdGroupCodeOptions: () => {
    return client.get(
      `${endpoint}/journal-document-templates/options/user-id-group-code`
    )
  },
  getJournalBookOptions: () => {
    return client.get(
      `${endpoint}/journal-document-templates/options/journal-book`
    )
  },
  getDataForGenerateJournalDocumentReport: params => {
    return client.get(
      `${endpoint}/report/journal-book-id/${params.journalBookId}/document-id/${params.documentId}`
    )
  }
}
