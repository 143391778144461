<template>
  <div class="h-100">
    <input
      class="input-class"
      v-model="value"
      placeholder="0.00"
      :id="idProps"
      @keydown="onKeyDownHandler"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import { inputFieldMixins, numberMixins } from '@/mixins'
import { mapState } from 'vuex'

export default {
  mixins: [numberMixins, inputFieldMixins],
  props: {
    valueProps: String | Number,
    idProps: String,
    itemProps: Object
  },
  data() {
    return {
      value: null,
      valueTimer: null
    }
  },
  computed: {
    ...mapState({
      stateConstantsDefaultKeywordTimerMs: (state) =>
        state.constants.defaultKeywordTimerMs
    })
  },
  watch: {
    value() {
      this.reformat(this.value)
    },
    valueProps() {
      this.reformat(this.valueProps)
    }
  },
  created() {
    this.value = !!this.valueProps
      ? this.mxNumeralThousandSeperate(this.valueProps, 2)
      : null
  },
  methods: {
    reformat(value) {
      let finalizedValue
      let skipErrorCase = false

      if (this.valueTimer) clearTimeout(this.valueTimer)

      // empty case
      if (
        value === null ||
        value === 'null' ||
        +value === 0 ||
        (_.isString(value) && value.trim() === '')
      ) {
        this.$emit('numberUpdated', null)
        this.value = null
        return
      }

      if (_.isString(value)) {
        // remove comma
        value = value.replace(/,/g, '')

        // handle case number with decimal point
        if (value.includes('.')) {
          const valueArray = value.split('')

          // remove duplicate decimal point
          const decimalPointFounds = valueArray.filter(
            (el) => el === '.'
          ).length
          if (decimalPointFounds > 1) {
            value = valueArray.slice(0, valueArray.length - 1).join('')
          }

          // number after decimal point should be only 2 digits
          const decimalPointIndex = valueArray.findIndex((el) => el === '.')
          const afterDecimalPointNumbers = valueArray.slice(
            decimalPointIndex + 1
          )
          if (afterDecimalPointNumbers.length > 2) {
            value = [
              ...valueArray.slice(0, decimalPointIndex),
              '.',
              ...afterDecimalPointNumbers.slice(0, 2)
            ].join('')

            finalizedValue = this.mxNumeralThousandSeperate(+value, 2)
            this.$emit('numberUpdated', finalizedValue.replace(/,/g, ''))
            this.value = finalizedValue
            return
          } else if (afterDecimalPointNumbers.length === 0) {
            skipErrorCase = true
          }
        }
      }

      // error case
      if (!skipErrorCase && isNaN(+value)) {
        this.$emit('numberUpdated', null)
        this.value = null
        return
      }

      this.valueTimer = setTimeout(() => {
        // handle case number without decimal point
        finalizedValue = this.mxNumeralThousandSeperate(+value, 2)
        this.$emit('numberUpdated', finalizedValue.replace(/,/g, ''))
        this.value = finalizedValue
      }, this.stateConstantsDefaultKeywordTimerMs)
    },
    rebalanceToRowSelected() {
      this.$emit('rebalanceRequested', this.itemProps.order)
    },
    onKeyDownHandler(event) {
      switch (event.key) {
        case 'Enter':
          if (!(this.value === null || this.value === '' || this.value === 0)) {
            this.value = this.mxNumeralThousandSeperate(this.value, 2)
          }
          this.mxFocusNextInputField()
          break
        case 'F4':
          this.rebalanceToRowSelected()
          break
      }
    }
  }
}
</script>

<style scoped>
.input-class {
  text-align: right;
  width: 100%;
  height: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
</style>
