export default {
  data() {
    return {
      style: {
        backgroundColorStyle: '#b8daff',
        borderStyle: `2px solid #dee2e6`,
        rowSelectedBackgroundColorStyle: '#dee2e6'
      }
    }
  },
  methods: {
    useClasses(key) {
      switch (key) {
        case 'HEADER':
          return 'd-flex align-items-center justify-content-center p-2 font-weight-bold'
        case 'FOOTER_CENTER':
          return 'd-flex align-items-center justify-content-center p-2 font-weight-bold'
        case 'FOOTER_RIGHT':
          return 'd-flex align-items-center justify-content-end p-2 font-weight-bold'
        case 'BODY_START':
          return 'd-flex align-items-center justify-content-start p-1'
        case 'BODY_CENTER':
          return 'd-flex align-items-center justify-content-center p-1'
        case 'BODY_END':
          return 'd-flex align-items-center justify-content-end text-right p-1'
      }
    },
    useStyles(key, payload = {}) {
      let result = ''
      switch (key) {
        case 'HEADER':
          result = `background-color: ${this.style.backgroundColorStyle}; `
          for (let styleKey of Object.keys(payload)) {
            let styleValue = payload[styleKey]
            result += `${styleKey}: ${styleValue}; `
          }
          return result
        case 'FOOTER':
          result = `background-color: ${this.style.backgroundColorStyle}; `
          for (let styleKey of Object.keys(payload)) {
            let styleValue = payload[styleKey]
            result += `${styleKey}: ${styleValue}; `
          }
          return result
        case 'BODY':
          result = `border: ${this.style.borderStyle}; `
          for (let styleKey of Object.keys(payload)) {
            let styleValue = payload[styleKey]
            result += `${styleKey}: ${styleValue}; `
          }
          return result
        default:
          return result
      }
    }
  }
}
